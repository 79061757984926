import { Component, OnInit } from '@angular/core';
import { COPYRIGHTS } from '@core/properties/copyrights';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public copyrightsMessage: string;

  private date = new Date();

  constructor() { }

  ngOnInit(): void {
    this.copyrightsMessage = 'Copyright © ' + COPYRIGHTS.StartingYear + ' – ' + this.date.getFullYear() + ' ' + COPYRIGHTS.CompanyName + '.  All rights reserved.';
  }
}
