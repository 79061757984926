import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggingService } from './service/logging.service';
import { ErrorService } from './service/error.service';
import { NotificationService } from './service/notification.service';
import { AuthError } from '@azure/msal-browser';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  constructor(private injector: Injector) { }

  handleError(error: Error | HttpErrorResponse) {

    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const notifier = this.injector.get(NotificationService);

    let message: string;
    let stackTrace: string;

    if (error instanceof HttpErrorResponse) {
      // Server Error
      message = errorService.getServerMessage(error);
      stackTrace = errorService.getServerStack(error);
      notifier.showError(message);
    } else if (error.message.indexOf('ChunkLoadError') > 0) {
      // Chunk loading error
      window.location.reload();
    } else {
      if (!(error instanceof AuthError && error.errorCode === 'no_token_request_cache_error')) {

        // Client Error
        message = errorService.getClientMessage(error);
        stackTrace = errorService.getClientStack(error);
        notifier.showError(message);
      }
    }
    // Always log errors
    logger.logError(error);
  }
}
