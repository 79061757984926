import {
  BrowserCacheLocation, Configuration, IPublicClientApplication,
  LogLevel, PublicClientApplication
} from '@azure/msal-browser';
import { environment } from '@env/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

const msalConfig: Configuration = {
  auth: {
    clientId: environment.clientID,
    authority: environment.b2cPolicies.authorities.signIn.authority,
    navigateToLoginRequestUrl: false,
    redirectUri: environment.redirectUri,
    postLogoutRedirectUri: environment.postLogoutRedirectUri,
    knownAuthorities: environment.knownAuthorities
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE, // set to true for IE 11
  },
  system: {
    loggerOptions: {
      loggerCallback,
      logLevel: LogLevel.Info,
      piiLoggingEnabled: false
    }
  }
};

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}
