export const environment = {
  production: false,
  idleStartTime: 300,
  idleSessionEndTime: 15,
  appInsightsInstrumentationKey: '8e645195-bbc1-49b8-822b-9d8d112e815c',
  knownAuthorities: ['capitaoneactprod.b2clogin.com'],
  redirectUri: 'https://pep.capitaone.cloud/',
  postLogoutRedirectUri: 'https://pep.capitaone.cloud/',
  ApiEndpoint: 'https://s014-oneapi-app-uks-api.azure-api.net/education/pep/',
  APIMSubscriptionKey: '07e4b0fe0e41438081d3fe6fc72aa010',

  tenant: 'capitaoneactprod.onmicrosoft.com',
  clientID: '9de0317b-33ad-467a-9596-0bc8bc84ea25',
  scopes: [
    'https://capitaoneactprod.onmicrosoft.com/pepuseraccess/user_impersonation',
    'offline_access',
    'openid'
  ],

  b2cPolicies: {
    names: {
      signIn: 'B2C_1_peplacsigninpolicy',
      resetPassword: 'B2C_1_pepresetpasswordflow'
    },
    authorities: {
      signIn: {
        authority: 'https://capitaoneactprod.b2clogin.com/capitaoneactprod.onmicrosoft.com/B2C_1_peplacsigninpolicy/'
      },
      resetPassword: {
        authority: 'https://capitaoneactprod.b2clogin.com/capitaoneactprod.onmicrosoft.com/B2C_1_pepresetpasswordflow/'
      }
    }
  }
};
