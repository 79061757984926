export class User {
  email;
  firstName;
  familyName;
  laId;
  laName;
  roleType;
  active;
  userId;
  status;
  phoneNumber;
  role;
  schools: School[];
  localAuthority: LocalAuthority;
}

export class School {
  active;
  schoolId;
  schoolName;
  laId;
  schoolNumber;
  revokeAccess;
}

export class LocalAuthority {
  active;
  administrator;
}
