import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInsightsService } from '@core/application-insights/application-insights.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {

  private appInsights: ApplicationInsightsService;

  constructor(
    private router: Router
  ) {
    this.appInsights = new ApplicationInsightsService(router);
  }

  logError(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (error.error && error.error.message !== undefined) {
        this.appInsights.logTrace(error.error.message + ' (' + error.error.code + ')');
      }
    }
    this.appInsights.logError(error);
    console.log(error);
  }
}
