import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuItem } from 'primeng/api';
import {  MessagingService } from '@core/services';
import { DEFAULT_MENU} from '@core/properties';
import { AuthService, UserService } from '@core/authentication';
import { User } from '@core/models';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [MessageService],
})
export class HeaderComponent implements OnInit, OnDestroy {

  public items: MenuItem[];

  public activeItem: MenuItem;

  public value: Date;

  display = false;
  public User: User;

  messageSubscription: Subscription;


  constructor(
    private authService: AuthService,
    private userService: UserService,
    private messageService: MessageService,
    private messagingService: MessagingService
  ) {
    // subscribe to notification service messages
    this.messageSubscription = this.messagingService.getMessage().subscribe(notification => {
      if (notification) {
        // Show all messages in toast component
        if (notification.Severity !== 'success') {
          this.messageService.add({
            severity: notification.Severity, sticky: true, summary: notification.Title,
            detail: notification.Message, closable: true
          });
        }
        else {
          this.messageService.add({
            severity: notification.Severity, summary: notification.Title,
            detail: notification.Message
          });
        }
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.messageSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.userService.userInfoReceived.subscribe(x => {
      this.items = [];
      if (this.userService.isAdminUser) {
        this.items = [
          { label: DEFAULT_MENU.EpepDashboard, routerLink: '/pepdocument/dashboard' }, { separator: true }
        ];
      }

      if (this.userService.isVirtualSchoolUser) {
        this.items.push({
          label: DEFAULT_MENU.Administration, routerLink: '/administration/manageusers'
        }, { separator: true }
        );
      }

      this.items.push({
        label: DEFAULT_MENU.Logout, command: (event) => {
          this.authService.logout();
        }
      });
    });
  }
}
