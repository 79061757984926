import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    // Needed for hash routing
    path: 'error',
    component: AppComponent
  },
  {
    // Needed for hash routing
    path: 'state',
    canActivate: [
      MsalGuard
    ],
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    // Needed for hash routing
    path: 'code',
    canActivate: [
      MsalGuard
    ],
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [
      MsalGuard
    ],
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'administration',
    canActivate: [
      MsalGuard
    ],
    loadChildren: () => import('./modules/administration/administration.module').then(m => m.AdministrationModule)
  },
  {
    path: 'pepdocument',
    canActivate: [
      MsalGuard
    ],
    loadChildren: () => import('./modules/pepdocument/pepdocument.module').then(m => m.PepdocumentModule)
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    useHash: true,
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabled' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
