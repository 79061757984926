import { Inject, OnDestroy, Injectable } from '@angular/core';
import {
  MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG,
  MsalGuardConfiguration
} from '@azure/msal-angular';
import {
  InteractionType, PopupRequest, RedirectRequest,
  AuthenticationResult
} from '@azure/msal-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { MSALInstanceFactory } from './MSALInstanceFactory';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private readonly _DESTROYING$ = new Subject<void>();

  private userData = new BehaviorSubject(false);
  private isAuthenticatedUser = new BehaviorSubject(false);
  private pepMSALApplication: any;

  public userInfoReceived = this.userData.asObservable();
  public isAuthenticated = this.isAuthenticatedUser.asObservable();
  public loginDisplay = false;
  public loggedInUserId: string;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {

    this.pepMSALApplication = MSALInstanceFactory();

    const promise = this.pepMSALApplication.handleRedirectPromise();
    promise.then(resp => {
      this.loggedInUserId = this.pepMSALApplication.getAllAccounts()[0].localAccountId;
      this.isAuthenticatedUser.next(true);
    }).catch(result => {
      // Check for forgot password error
      // Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
      if (result.errorMessage.includes('AADB2C90118')) {
        this.login({
          scopes: environment.scopes,
          authority: environment.b2cPolicies.authorities.resetPassword.authority
        });
      }
    });
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  logout() {
    this.authService.logout();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._DESTROYING$.next(undefined);
    this._DESTROYING$.complete();
  }
}
