import { Component, OnInit } from '@angular/core';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { environment } from '@env/environment';
import { AuthService } from '@core/authentication';

@Component({
  selector: 'app-session-timer',
  templateUrl: './session-timer.component.html'
})
export class SessionTimerComponent implements OnInit {

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  timer: number;
  displayModal: boolean;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private authService: AuthService
  ) {

    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(environment.idleStartTime);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(environment.idleSessionEndTime);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      // this.authService.logout();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      this.displayModal = true;
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.timer = countdown;
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      if (countdown === 1) {
        this.authService.logout();
      }
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(environment.idleSessionEndTime);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  ngOnInit(): void {
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  stayLoggedIn() {
    this.displayModal = false;
  }

  logOut() {
    this.authService.logout();
  }

}
