import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { User } from '@core/models';
import { ApiServiceService } from '@core/api';
import { ENDPOINT_ROUTES } from '@core/properties';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  LAName: string;
  LaID: number;
  UserId: string;
  userType: string;
  roleType: number;
  customErrorMessage: string;
  LaWeeks: number;
  SchoolWeeks: number;
  schoolUserContextId: number;
  User: User;
  pepUsers: User[];
  private userData = new BehaviorSubject(false);
  public userInfoReceived = this.userData.asObservable();

  private schoolContext = new BehaviorSubject(false);
  public schoolContextChanged = this.schoolContext.asObservable();

  constructor(
    private apiservice: ApiServiceService,
    private authService: AuthService,
    private router: Router
  ) {

    this.authService.isAuthenticated.subscribe(data => {
      if (data === true) {
        this.UserId = this.authService.loggedInUserId;
        this.apiservice.userId = this.UserId;
        this.getUserRoleType();
      }
    });
  }

  get isUserNotSet(): boolean { return this.roleType === 0; }
  get isChildUser(): boolean { return this.roleType === 1; }
  get isTeacherUser(): boolean { return this.roleType === 2; }
  get isSocialWorker(): boolean { return this.roleType === 3; }
  get isVirtualSchoolUser(): boolean { return this.roleType === 4; }
  get isAdminUser() {
    return this.isVirtualSchoolUser ||
      this.isSocialWorker ||
      this.isTeacherUser;
  }


  getUserRoleType() {
    // API call to get user type
    this.apiservice.get<User>(ENDPOINT_ROUTES.User, this.UserId).subscribe({
      next: (x: any) => {
        this.User = x.user[0];
        this.roleType = x.user[0].roleType;

        this.LaID = x.user[0].laId;
        this.apiservice.userId = this.UserId;

        if (this.isAdminUser) {
          this.LAName = x.user[0].laName;
          this.apiservice.laId = this.LaID;

          this.userData.next(true);
          this.router.navigateByUrl('pepdocument/dashboard');
        }

        if (this.isChildUser) {
          this.LAName = x.user[0].laName;
          this.apiservice.laId = this.LaID;
          this.router.navigateByUrl('pepdocument/voiceofchild');
        }

        this.getUsers();
      },
      error: (x: any) => {
        if (x.status === 404) {
          alert('User is Inactive');
          this.authService.logout();
        }
      }
    });
  }

  getUsers() {
    // API call to get user type
    this.apiservice.getAll<User[]>(ENDPOINT_ROUTES.User, '').subscribe({
      next: (x: any) => {
        this.pepUsers = x.users;
      },
      error: (x: any) => {
      }
    });
  }
}

export enum RoleTypeEnum {
  NoSet,
  Child,
  Carer,
  Teacher,
  Vst,
  Iro,
  Sw
}
