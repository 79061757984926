import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  isMinDate(dateToBeChecked: Date): boolean {
    return !dateToBeChecked || dateToBeChecked == null || new Date(dateToBeChecked).getFullYear() === 1;
  }

  sortByDate(firstStudent, secondStudent): number {
    const aMaxDate = new Date(Math.max.apply(null, firstStudent.schoolHistory.map(x => new Date(x.startDate))));
    const bMaxDate = new Date(Math.max.apply(null, secondStudent.schoolHistory.map(x => new Date(x.startDate))));

    return aMaxDate > bMaxDate ? -1 : 1;
  }
}
