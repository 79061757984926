export const ENDPOINT_ROUTES = {
  User: 'pepusers',
  GetStudentsRequiringPep: 'peps/dashboard/details',
  StudentInformationBase: 'pepStudents/',
  StudentCarerInformation: '/carers',
  StudentExclusionInformation: '/exclusions',
  StudentSchoolHistoryInformation: '/schoolhistory',
  StudentSchoolAttendanceInformation: '/pepstudent/attendance',
  GetDashboardSummary: 'peps/dashboard/summary',
  GetCareInformationLegalOptions: 'peps/careinformation/legalstatusoptions',
  StudentPepDocument: 'peps',
  StudentCareInformation: '/careinformation',
  StudentTargetInformation: '/targets',
  StudentTargetReviewInformation: '/Targets/Review',
  StudentAttainment: '/attainments',
  Questions: '/questions',
  AdditionalSupport: '/additionalsupport',
  SENStage: '/sen/stageoptions',
  PrimarySecondaryNeed: '/additionalsupport/childprimaryneedoptions',
  SupportTeamUser: '/supportteam',
  Meeting: '/meeting',
  NextMeeting: '/nextpepmeeting',
  MeetingInviteEmail: '/meeting/email',
  UpdateSupportTeamUser: '/supportteam/user',
  Roles: 'pepusers/roles'
};
