import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

interface QueryParams {
  [key: string]: string | number;
}

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  private endPoint = environment.ApiEndpoint;
  public laId: number;
  public userId: string;

  constructor(
    private http: HttpClient
  ) { }

  post<T>(route: string, data: any, qp: QueryParams = {}): Observable<T> {
    const headers = this.getHeaders();
    const cfqu = this.correctFormatForQueryUrl(qp);
    return this.http.post(
      this.endPoint + route + cfqu, data, { headers }) as Observable<T>;
  }

  put<T>(route: string, data: any, qp: QueryParams = {}): Observable<T> {
    const headers = this.getHeaders();
    const cfqu = this.correctFormatForQueryUrl(qp);
    return this.http.put(
      this.endPoint + route, data, { headers }) as Observable<T>;
  }

  putWithResponseHeader(route: string, data: any, qp: QueryParams = {}): Observable<HttpResponse<object>> {
    const headers = this.getHeaders();
    const cfqu = this.correctFormatForQueryUrl(qp);
    return this.http.put<HttpResponse<object>>(this.endPoint + route, data, { headers, observe: 'response' });
  }

  postWithResponseHeader(route: string, data: any, qp: QueryParams = {}): Observable<HttpResponse<object>> {
    const headers = this.getHeaders();
    const cfqu = this.correctFormatForQueryUrl(qp);
    return this.http.post<HttpResponse<object>>(this.endPoint + route + cfqu, data, { headers, observe: 'response' });
  }

  delete<T>(route: string, filter: any, qp: QueryParams = {}): Observable<T> {
    const headers = this.getHeaders();
    const cfqu = this.correctFormatForQueryUrl(qp);
    if (filter && filter.length === 2) {
      return this.http.delete(
        `${this.endPoint}/${route}${filter[0] ? '/' + filter[1] : ''}${cfqu}`, { headers }
      ) as Observable<T>;
    }
    else {
      return this.http.delete(
        `${this.endPoint}/${route}`, { headers }
      ) as Observable<T>;
    }
  }

  getAll<T>(route: any, filter: any, qp: QueryParams = {}): Observable<T> {
    const cfqu = this.correctFormatForQueryUrl(qp);
    const headers = this.getHeaders();
    return this.http.get<any>(this.endPoint + route + filter + cfqu, { headers }) as Observable<any>;
  }

  get<T>(route: string, filter: any, qp: QueryParams = {}): Observable<T> {
    const cfqu = this.correctFormatForQueryUrl(qp);
    const cffilter = this.correctFormatForFilterUrl(filter);
    const headers = this.getHeaders();
    return this.http.get<any>(this.endPoint + route + cffilter + cfqu, { headers }) as Observable<any>;
  }

  /**
   * In the return we will attach the '?' if the user provides a query params
   * and if the user provides a null we do not need to map the array to
   * anything, we just simply returns ''.
   * if there qp dose has some keys an values
   * e.g
   * const z = {userId: 1, name: 'rowad'} then
   * this method will return ["userId=1", "name=rowad"]
   */
  private correctFormatForQueryUrl(qp: QueryParams): string {
    // TODO Add a null check
    // if (isNull(qp)) {
    //   return '';
    // }
    const qpAsStr = this.mapQueryParamsToUrl(qp);
    return qpAsStr.length === 0 ? '' : `?${qpAsStr.join('&')}`;
  }

  /**
   * In the return we will attach the '/' if the user provides a filters
   * and if the user provides a null we do not need to add
   * anything, we just simply returns ''.
   */
  private correctFormatForFilterUrl(filter: string): string {
    if (filter) {
      return '/' + filter;
    } else {
      return '';
    }
  }


  /**
   * e.g :
   * const z = {userId: 1, name: 'rowad'} then
   * this method will return ["userId=1", "name=rowad"]
   */
  private mapQueryParamsToUrl(qp: QueryParams): Array<string> {
    return Object.keys(qp).map((key: string) => {
      return `${key}=${qp[key]}`;
    });
  }

  private getHeaders() {
    return {
      'Ocp-Apim-Subscription-Key': environment.APIMSubscriptionKey,
      ConnectionId: 'Education' + this.laId,
      'X-MS-CLIENT-PRINCIPAL-ID': this.userId
    };
  }

}
