import { Injectable, Injector } from '@angular/core';
import { MessagingService } from '@core/services';
import { Notification } from '../notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notification: Notification;

  constructor(private messagingService: MessagingService) { }

  // Show success message
  showSuccess(message: string, title?: string): void {
    if (title) {
      this.sendMessage('success', title, message);
    }
    else {
      this.sendMessage('success', 'Success', message);
    }
  }

  showInformation(message: string, title?: string): void {
    if (title) {
      this.sendMessage('info', title, message);
    }
    else {
      this.sendMessage('info', 'Information', message);
    }
  }

  showWarning(message: string, title?: string): void {
    if (title) {
      this.sendMessage('warn', title, message);
    }
    else {
      this.sendMessage('warn', 'Warning', message);
    }
  }

  showError(message: string, title?: string): void {
    if (title) {
      this.sendMessage('error', title, message);
    }
    else {
      this.sendMessage('error', 'Error', message);
    }
  }

  private sendMessage(type: string, title: string, message: string) {
    this.notification = new Notification();
    this.notification.Message = message;
    this.notification.Title = title;
    this.notification.Severity = type;
    this.messagingService.sendMessage(this.notification);
  }
}
