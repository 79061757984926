import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Notification } from '@core/error-handling';

@Injectable({ providedIn: 'root' })
export class MessagingService {
    private infoSubject = new Subject<Notification>();

    sendMessage(notification: Notification) {
        this.infoSubject.next( notification);
    }
    getMessage(): Observable<Notification> {
        return this.infoSubject.asObservable();
    }
}
