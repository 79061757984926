import { DateService } from './services/date.service';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { SessionTimerComponent } from './components/session-timer/session-timer.component';
import { DataService } from './services/data.service';
import { HyphenPipe } from './pipes/hyphen.pipe';
import { AddRowDirective } from './directives/add-row.directive';

@NgModule({
  declarations: [
    SessionTimerComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    DialogModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  exports: [
    SessionTimerComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [DataService, DateService, DatePipe]
    };
  }
}
